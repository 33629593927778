import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FileUploadModule } from 'mppr-ng2-file-upload';

@NgModule({
  declarations: [


  ],
  imports: [CommonModule, FormsModule, FileUploadModule],
  exports: [CommonModule, FormsModule, FileUploadModule],
})
export class DiversosModule {}
