import { Injectable } from '@angular/core';
import { Atendimento } from '../../../shared/modelos/atendimento.model';
import { ToastrService, ToastrModule } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class AtendimentoCadastrarValidarService {
  constructor(private toastr: ToastrService) {
  }

  validaDadosCadastrais(atendimento: Atendimento,flagNaoInformaCpf:boolean,
                        flagNaoInformaRg:boolean,flagCpfValido:boolean,
                        flagEmailValido:boolean,flagSelecaoPais:boolean) {

    var msg : string = '';

    // console.log (flagNaoInformaCpf," - ",flagNaoInformaRg)

    // Verifica se há um campo não preenchido
    if ( atendimento.pessoa.nome == '' ||
         atendimento.dadosComplementares.sexo == '' ||
         (atendimento.dadosComplementares.numCpf == '' && !flagNaoInformaCpf && !flagNaoInformaRg) ||
         (atendimento.dadosComplementares.numRg == '' && flagNaoInformaCpf && !flagNaoInformaRg) ||
         (atendimento.dadosComplementares.ufRg == '' && flagNaoInformaCpf && !flagNaoInformaRg) ||
         atendimento.pessoa.dataNascimento == '' ||
         atendimento.pessoa.nomeMae == '' ||
         atendimento.dadosComplementares.telefone == '' ||
         atendimento.dadosComplementares.email == '' ||
         !atendimento.dadosComplementares.idPais && flagSelecaoPais
        //  atendimento.dadosComplementares.ufMoradia == '' ||
        //  atendimento.dadosComplementares.cidadeMoradia == '' ||
       ) {
            msg = "Preencher os campos obrigatórios:";
            if (atendimento.pessoa.nome == '' )
              msg += '</br> - Nome';
            if (atendimento.dadosComplementares.sexo == '' )
              msg += '</br> - Sexo';
            if (atendimento.dadosComplementares.numCpf == '' && !flagNaoInformaCpf && !flagNaoInformaRg)
              msg += '</br> - CPF ou selecione \'Não Informado\'';
            if (atendimento.dadosComplementares.numRg == '' && flagNaoInformaCpf && !flagNaoInformaRg)
              msg += '</br> - RG ou selecione \'Não Informado\'';
            if (atendimento.dadosComplementares.ufRg == '' && flagNaoInformaCpf && !flagNaoInformaRg)
              msg += '</br> - Estado do RG ou selecione \'Não Informado\'';
            if (atendimento.pessoa.dataNascimento == '')
              msg += '</br> - Data de Nascimento';
            if (atendimento.pessoa.nomeMae == '')
              msg += '</br> - Nome da mãe';
            if (atendimento.dadosComplementares.telefone == '')
              msg += '</br> - Telefone';
            if (atendimento.dadosComplementares.email == '')
              msg += '</br> - Email';
            if (atendimento.dadosComplementares.telefone.length < 10)
              msg += '</br> - Telefone deve ter pelo menos 10 dígitos, incluso DDD';
            // if (!atendimento.dadosComplementares.idPais && flagSelecaoPais)
            //   msg += '</br> - Nacionalidade';
            //  atendimento.dadosComplementares.ufMoradia == '' ||
            //  atendimento.dadosComplementares.cidadeMoradia == '' ||
            this.toastr.error(msg,'',{enableHtml : true});
            return false;
         }

    // Verifica se há campos inválidos
    else if (!flagCpfValido || !flagEmailValido) {

           if (!flagCpfValido) {
                 msg = 'CPF inválido'
             };

           if (!flagEmailValido) {
                 msg==''?msg = "E-mail inválido":msg += ',E-mail inválido'
              };

           this.toastr.error(msg);
           return false;
         }

    else {

      return true;
   }
  }

  cpfValido(cpf:string) {
    if (typeof cpf !== "string") return false
    cpf = cpf.replace(/[\s.-]*/igm, '')
    if (
        !cpf ||
        cpf.length != 11 ||
        cpf == "00000000000" ||
        cpf == "11111111111" ||
        cpf == "22222222222" ||
        cpf == "33333333333" ||
        cpf == "44444444444" ||
        cpf == "55555555555" ||
        cpf == "66666666666" ||
        cpf == "77777777777" ||
        cpf == "88888888888" ||
        cpf == "99999999999"
    ) {
        return false
    }
    var soma = 0
    var resto
    for (var i = 1; i <= 9; i++)
        soma = soma + parseInt(cpf.substring(i-1, i)) * (11 - i)
    resto = (soma * 10) % 11
    if ((resto == 10) || (resto == 11))  resto = 0
    if (resto != parseInt(cpf.substring(9, 10)) ) return false
    soma = 0
    for (var i = 1; i <= 10; i++)
        soma = soma + parseInt(cpf.substring(i-1, i)) * (12 - i)
    resto = (soma * 10) % 11
    if ((resto == 10) || (resto == 11))  resto = 0
    if (resto != parseInt(cpf.substring(10, 11) ) ) return false
    return true
  }

  emailValido(email:string) {
      var re = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i;
      return re.test(email);
  }

  existeDescricaoMotivo(motivo:string) {
    var msg : string = "Solicitação é obrigatória";
    if (motivo.trim().length > 0) {
      return true;
    }
    else {
           this.toastr.error(msg);
           return false;
    }
  }

  minimoPalavras(motivo:string) {
    var msg : string = "Descrição da Solicitação deve ter um mínimo de 3 palavras";
    if (motivo.trim().split(/\s+/).length >= 3) {
      return true;
    }
    else {
           this.toastr.error(msg);
           return false;
    }
  }

  contadorMinimoPalavras(motivo:string) {
    if (motivo.trim().split(/\s+/).length >= 3) {
      return true;
    }
    else {
           return false;
    }
  }

}
